
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=League+Spartan:wght@400;700&family=Montserrat:wght@400;700&display=swap');

body {
  background: #F8F7F3;
  position: relative;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
}