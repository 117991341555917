.BierContainer {
    margin-top: 10%;

    h1 {
        color: #041517;
    }

    div {
        ul {
            li {
                
                padding-top: .5rem;

                span {
                    width: 100%;
                    opacity: 0.97;
                    padding: 0 1rem 0 0;
                    width: 45px;
                }
                span:nth-child(1) {
                    font-weight: bold;
                    width: inherit;
                }
                span:nth-child(2) {
                    padding-right: 0;
                }
                span:nth-child(3) {
                    font-style: italic;
                }
                span:nth-child(4) {
                    font-weight: bold;
                }
            }
        }
    }

    @media (max-width: 500px) {
        h1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        }
       div {
        display: flex;
        flex-direction: column;
        align-items: center;
            ul {
                li {
                    float: left;
                    span{
                        display: block;
                    }
                    span:nth-child(1) {
                    width: 100%;
                    border-bottom: 1px solid lightgrey;
                    }

                    span:nth-child(3) {
                        font-style: italic;
                    }
                    span:nth-child(4) {
                        font-weight: bold;
                        padding-left: 0;
                    }
                    }
        }
       }
    }
}

.BierLine {
    height: 2px;
    width: 100%;
    background: #041517;
}

.BierBedrijfNaam {
    font-weight: bold;
    font-size: 20px;
    padding-top: 1.5rem;
    text-decoration: underline;
    @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        width: 100%;
    }
}

.BierDottedList {
    ul.leaders {
        padding: 0;
        overflow-x: hidden;
        list-style: none
        }
        
        ul.leaders li:before {
        float: left;
        width: 0;
        white-space: nowrap;
        content:
        ". . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . "
        }
        
        ul.leaders span:first-child {
            padding-right: 0.33em;
            background: #F8F7F3;
            }
        
        ul.leaders span + span {
            float: right;
            padding-left: 0.33em;
            background: #F8F7F3;
        }
        
        @media (max-width: 500px) {
            ul.leaders li:before {
            content: "";
        } 
           }
        
}