.NavbarContainer {
    position: fixed;
    z-index: 30;
    width: 100vw;
    min-height: 10vh;
    background: #041517;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;  

    div {
        padding: 12px 10% 12px 10%;
        a {

            img {
                height: 100%;
                width: 150px;
                
            }
        }
    }

    div:nth-child(2) {
        font-family: 'League Spartan', sans-serif;
        a {
            text-decoration: none;
            color: white;
            font-size: 24px;
            display: inline-block;
            position: relative;
            letter-spacing: 2px;
        }

        a::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: -2px;
        left: 0;
        background-color: white;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        }

        a:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
        }
    }

    @media (max-width: 400px) {
        div {
            padding: 12px 5% 12px 5%;

            a{

                img{

                    width: 100px;
                }
            }
        }

        div:nth-child(2) {
        a {
           font-size: 20px;
        }

        
    }
}
}