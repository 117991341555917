.FooterContainer {
    background: #041517;
    color: white;
    padding: 0 10% 0 10%;
    font-family: 'League Spartan',sans-serif;

    div {

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-self: center;

        @media (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
        
  }

        @media (max-width: 550px) {
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
         
  }


        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 2rem 0 2rem 0;

            p {
                padding-top: 5px;
                opacity: 80%;
            }

            div {
                display: flex;
                flex-direction: row;
                padding: 0;

                svg {
                    width: 24px;
                    fill: white;
                    padding: 5px 10px 0 0;
                }
            }
        }
    }
}